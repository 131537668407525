
export interface IResponse {
    status: any;
    message: any;
    data: any;
    totalRecords:any;
}

export class ResponseData implements IResponse {
    public status: any;
    public message: any;
    public data: any;
    public totalRecords:any;
}
