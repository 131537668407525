import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardDashboardGuard implements CanActivate {
  constructor(private router: Router) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let userRoles = localStorage.getItem('sw_roles');

    if (!userRoles) {
      this.router.navigate(['auth/login'])
    }
    let roles = userRoles.split(',');

    if (roles.indexOf("Admin") > -1) {
      return true;
    }
    else if (roles.indexOf("Sys Admin") > -1) {
      return true;
    }
    else if (roles.indexOf("Sub Admin") > -1) {
      return true;
    }
    else {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('sw_roles');
      this.router.navigate(['auth/login'])
    }
  }

}
