import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}

const MENUITEMS = [
  {
    label: 'Navigation',
    main: [
      {
        state: 'dashboard',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-home'
      },
      {
        state: 'appuser',
        name: 'Users',
        type: 'link',
        icon: 'ti-user'
      },
      {
        state: 'category',
        name: 'Category',
        type: 'link',
        icon: 'ti-layers'
      },
      {
        state: 'channel',
        name: 'Channel',
        type: 'link',
        icon: 'ti-video-clapper'
      },
      {
        state: 'show',
        name: 'Show',
        type: 'link',
        icon: 'ti-layout-tab-window'
      },
      {
        state: 'track',
        name: 'Track',
        type: 'sub',
        icon: 'ti-headphone-alt',
        children: [
          {
            state: 'tracks',
            name: 'Track'
          },
          {
            state: 'track-bulk',
            name: 'Track-bulk'
          }
        ]
      },
      {
        state: 'book',
        name: 'Book',
        type: 'sub',
        icon: 'ti-book',
        children: [
          {
            state: 'books',
            name: 'Book'
          },
          {
            state: 'book-bulk',
            name: 'Book-bulk'
          }
        ]
      },
      {
        state: 'wisdom',
        name: 'Wisdom',
        type: 'link',
        icon: 'ti-pencil-alt'
      },
      {
        state: 'comment',
        name: 'comment',
        type: 'link',
        icon: 'ti-pencil-alt'
      },
      {
        state: 'patchbillboard',
        name: 'Patch & Billboard',
        type: 'sub',
        icon: 'ti-layers',
        children: [
          {
            state: 'patch',
            name: 'Patch'
          },
          {
            state: 'billboard',
            name: 'Billboard'
          }
        ]
      },
      // {
      //   state: 'playlist',
      //   name: 'Playlist',
      //   type: 'link',
      //   icon: 'ti-layers'
      // },
      // {
      //   state: 'podcast',
      //   name: 'Podcast',
      //   type: 'link',
      //   icon: 'ti-layers'
      // },
    ],
  },
  // {
  //   label: 'Others',
  //   main: [
  //     {
  //       state: 'auth',
  //       short_label: 'A',
  //       name: 'Authentication',
  //       type: 'sub',
  //       icon: 'ti-lock',
  //       children: [
  //         {
  //           state: 'login',
  //           type: 'link',
  //           name: 'Login',
  //           target: true
  //         }, {
  //           state: 'registration',
  //           type: 'link',
  //           name: 'Registration',
  //           target: true
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: 'UI Element',
  //   main: [
  //     {
  //       state: 'basic',
  //       name: 'Basic Components',
  //       type: 'sub',
  //       icon: 'ti-layout-grid2-alt',
  //       children: [
  //         {
  //           state: 'button',
  //           name: 'Button'
  //         },
  //         {
  //           state: 'typography',
  //           name: 'Typography'
  //         }
  //       ]
  //     },
  //     {
  //       state: 'notifications',
  //       name: 'Notifications',
  //       type: 'link',
  //       icon: 'ti-crown'
  //     }
  //   ]
  // },
  // {
  //   label: 'Forms',
  //   main: [
  //     {
  //       state: 'forms',
  //       name: 'Form Components',
  //       type: 'link',
  //       icon: 'ti-layers'
  //     }
  //   ]
  // },
  // {
  //   label: 'Tables',
  //   main: [
  //     {
  //       state: 'bootstrap-table',
  //       name: 'Bootstrap Table',
  //       type: 'link',
  //       icon: 'ti-receipt'
  //     }
  //   ]
  // },
  // {
  //   label: 'Map',
  //   main: [
  //     {
  //       state: 'map',
  //       name: 'Maps',
  //       type: 'link',
  //       icon: 'ti-map-alt'
  //     }
  //   ]
  // },
  // {
  //   label: 'Menu Level',
  //   main: [
  //     {
  //       state: '',
  //       name: 'Menu Levels',
  //       type: 'sub',
  //       icon: 'ti-direction-alt',
  //       children: [
  //         {
  //           state: '',
  //           name: 'Menu Level 2.1',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.2',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.2.2',
  //               target: true
  //             }
  //           ]
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.3',
  //           target: true
  //         }, {
  //           state: '',
  //           name: 'Menu Level 2.4',
  //           type: 'sub',
  //           children: [
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.1',
  //               target: true
  //             },
  //             {
  //               state: '',
  //               name: 'Menu Level 2.4.2',
  //               target: true
  //             }
  //           ]
  //         }
  //       ]
  //     }, {
  //       state: 'simple-page',
  //       name: 'Simple Page',
  //       type: 'link',
  //       icon: 'ti-layout-sidebar-left'
  //     }
  //   ]
  // }
];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }

  /*add(menu: Menu) {
    MENUITEMS.push(menu);
  }*/
}
