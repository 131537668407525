import { AppUserModule } from './app-user/app-user.module';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './layouts/admin/admin.component';
import { AuthComponent } from './layouts/auth/auth.component';
import { AppUserGuardGuard } from './app-user/app-user-guard.guard';
import { AuthGuardDashboardGuard } from './dashboard/auth-guard-dashboard.guard';
import { ImageCropperComponent } from './image-cropper/component/image-cropper.component';

const routes: Routes = [
  {
    path: '', component: AdminComponent,
    children: [
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuardDashboardGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'category',
        loadChildren: () => import('./category/category.module').then(m => m.CategoryModule)
      },
      {
        path: 'channel',
        loadChildren: () => import('./channel/channel.module').then(m => m.ChannelModule)
      },
      {
        path: 'show',
        loadChildren: () => import('./show/show.module').then(m => m.ShowModule)
      },
      {
        path: 'track',
        loadChildren: () => import('./track/track.module').then(m => m.TrackModule)
      },
      {
        path: 'patchbillboard',
        loadChildren: () => import('./patch-billboard/patch-billboard.module').then(m => m.PatchBillboardModule)
      },
      {
        path: 'playlist',
        loadChildren: () => import('./playlist/playlist.module').then(m => m.PlaylistModule)
      },
      {
        path: 'podcast',
        loadChildren: () => import('./podcast/podcast.module').then(m => m.PodcastModule)
      },
      {
        path: 'wisdom',
        loadChildren: () => import('./wisdom/wisdom.module').then(m => m.WisdomModule)
      },
      {
        path: 'comment',
        loadChildren: () => import('./comment/comment.module').then(m => m.CommentModule)
      },
      {
        path: 'book',
        loadChildren: () => import('./book/book.module').then(m => m.BookModule)
      },
      {
        path: 'appuser',
        canActivate: [AppUserGuardGuard],
        loadChildren: () => import('./app-user/app-user.module').then(m => m.AppUserModule)
      },
      {
        path: 'basic',
        loadChildren: () => import('./components/basic/basic.module').then(m => m.BasicModule)
      }, {
        path: 'notifications',
        loadChildren: () => import('./components/advance/notifications/notifications.module').then(m => m.NotificationsModule)
      }, {
        path: 'forms',
        loadChildren: () => import('./components/forms/basic-elements/basic-elements.module').then(m => m.BasicElementsModule)
      }, {
        path: 'bootstrap-table',
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('./components/tables/bootstrap-table/basic-bootstrap/basic-bootstrap.module').then(m => m.BasicBootstrapModule),
      }, {
        path: 'map',
        loadChildren: () => import('./map/google-map/google-map.module').then(m => m.GoogleMapModule),
      }, {
        path: 'simple-page',
        loadChildren: () => import('./simple-page/simple-page.module').then(m => m.SimplePageModule)
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },

  // {
  //   path: 'image-crop',
  //   component: ImageCropperComponent,
  // },
  // {
  //   path: '**',
  //   redirectTo: 'dashboard'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
