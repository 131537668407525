// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  //CONTENT_API_URL: 'https://localhost:44337/api/',
  // SECURITY_API_URL: 'https://localhost:44356/api/',
  // HUB_BASE_URL : 'https://localhost:44337/',
  // // CONTENT_BASE_URL : 'https://d1lqqmha36tgjj.cloudfront.net/',
  // CONTENT_BASE_URL : 'https://saawt-contents.s3.me-south-1.amazonaws.com/',

  CONTENT_API_URL: 'https://content.saawt.com/api/',
  SECURITY_API_URL: 'https://security.saawt.com/api/',
  HUB_BASE_URL: 'https://content.saawt.com/',
  CONTENT_BASE_URL: 'https://saawt-contents.s3.me-south-1.amazonaws.com/',

  ITEMS_PER_PAGE: 20
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
