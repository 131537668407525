import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpEvent, HttpEventType, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IResponse, ResponseData } from './shared/model/ApiResponse';
import { ICategory } from './category/model/category';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  itemsPerPage = environment.ITEMS_PER_PAGE;
  apiUrl = environment.CONTENT_API_URL;
  apiUrlSecuruty = environment.SECURITY_API_URL;
  hubUrl = environment.HUB_BASE_URL;
  token = window.localStorage.getItem('token');

  reqHeader = new HttpHeaders({
    // 'Content-Type': 'application/json',
    // tslint:disable-next-line: max-line-length
    Authorization: 'Bearer ' + this.token
  });

  constructor(public http: HttpClient, private router: Router
    , private spinner: NgxSpinnerService) { }

  getAll(skip?: number, take?: number): Observable<IResponse> {
    return this.http.get<IResponse>(this.apiUrl + skip + '/' + take, { headers: this.reqHeader });
  }
  getById(id: string): Observable<IResponse> {
    return this.http.get<IResponse>(this.apiUrl + id, { headers: this.reqHeader });
  }
  upload(data, userId): Observable<IResponse> {
    return this.http.post<any>(this.apiUrl, data, {
      reportProgress: true, observe: 'events', headers: this.reqHeader
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
  create(data: FormData): Observable<any> {
    // return this.http.post<IResponse>(this.apiUrl, data, { headers: this.reqHeader });
    let res = new ResponseData();
    return this.http.post<any>(this.apiUrl, data, {
      reportProgress: true, observe: 'events', headers: this.reqHeader
    }).pipe(map((event) => {

      switch (event.type) {

        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          res.status = 'progress'; res.message = progress;
          return res; // { status: 'progress', message: progress };
        case HttpEventType.Response:
          res = event.body;
          return res; // event.body;
        default:
          // return `Unhandled event: ${event.type}`; return { status: 'unhandled', message: '' };
          res.status = 'unhandled'; res.message = '';
          return res;
      }
    }));
  }

  update(data: FormData): Observable<any> {
    // return this.http.put<IResponse>(this.apiUrl, data, { headers: this.reqHeader });
    let res = new ResponseData();
    const uploadReq = new HttpRequest('PUT', this.apiUrl, data,
      { reportProgress: true, headers: this.reqHeader });
    return this.http.request<any>(uploadReq).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          res.status = 'progress'; res.message = progress;
          return res; // { status: 'progress', message: progress };
        case HttpEventType.Response:
          res = event.body;
          return res; // event.body;
        default:
          // return `Unhandled event: ${event.type}`; return { status: 'unhandled', message: '' };
          res.status = 'unhandled'; res.message = '';
          return res;
      }
    }));
  }
  updateWithUrl(url: string, data: FormData, url2?: string): Observable<any> {
    let res = new ResponseData();
    let apiUrl = this.apiUrl + url;
    if (url2) {
      apiUrl = apiUrl + "/" + url2;
    }
    const uploadReq = new HttpRequest("PUT", apiUrl, data, {
      reportProgress: true,
      headers: this.reqHeader,
    });
    return this.http.request<any>(uploadReq).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round((100 * event.loaded) / event.total);
            res.status = "progress";
            res.message = progress;
            return res; // { status: 'progress', message: progress };
          case HttpEventType.Response:
            res = event.body;
            return res; // event.body;
          default:
            // return `Unhandled event: ${event.type}`; return { status: 'unhandled', message: '' };
            res.status = "unhandled";
            res.message = "";
            return res;
        }
      })
    );
  }
  delete(id: string): Observable<IResponse> {

    return this.http.delete<IResponse>(this.apiUrl + id, { headers: this.reqHeader });
  }
  userLogin(formData: FormData): Observable<IResponse> {
    return this.http.post<IResponse>(this.apiUrl + 'login', formData);
  }

  showSpinner() {
    this.spinner.show();
    // setTimeout(() => {
    //     /** spinner ends after 5 seconds */
    //     this.spinner.hide();
    // }, 5000);
  }

  hideSpiner() {
    this.spinner.hide();
    // setTimeout(() => {
    //     /** spinner ends after 5 seconds */
    //     this.spinner.hide();
    // }, 5000);
  }
}
